// We need to import the CSS so that webpack will load it.
// The MiniCssExtractPlugin is used to separate it out into
// its own CSS file.
import "../css/app.scss";

// webpack automatically bundles all modules in your
// entry points. Those entry points can be configured
// in "webpack.config.js".
//
// Import dependencies
//
import "phoenix_html";
import { Socket } from "phoenix";
import { LiveSocket, debug } from "phoenix_live_view";
import _ from "lodash";
import ClipboardJS from "clipboard";
import Dropzone from "dropzone";

// Import local files
//
// Local files can be imported directly using relative paths, for example:
// import socket from "./socket"
import Appsignal from "@js/lib/appsignal.js";

Appsignal.addDecorator(span => span.setAction("app.js"));

// https://stackoverflow.com/questions/33076495/how-can-i-fix-this-dropzone-already-attached-error
Dropzone.autoDiscover = false;

let clipboard;
let autocomplete_clickaway_listener;

const hooks = {
  SearchField: {
    mounted() {
      const search = _.debounce(
        event => this.pushEvent("search", { search_term: event.target.value }),
        300
      );
      this.el.addEventListener("input", search);
    }
  },
  EntriesPerPage: {
    mounted() {
      const set_page_size = _.debounce(
        event =>
          this.pushEvent("set_page_size", { page_size: event.target.value }),
        500
      );
      this.el.addEventListener("input", set_page_size);
    }
  },
  Modal: {
    mounted() {
      document.body.classList.add("modal-open");
    },
    destroyed() {
      document.body.classList.remove("modal-open");
    }
  },
  Autocomplete: {
    mounted() {
      this.el.querySelector("input").focus();

      const phx_event = this.el.getAttribute("clickaway-event");
      const phx_event_target = this.el.getAttribute("clickaway-event-target");

      autocomplete_clickaway_listener = event => {
        if (
          phx_event &&
          phx_event_target &&
          !this.el.contains(event.target) &&
          !event.target.attributes["disable-clickaway-event"]
        ) {
          this.pushEventTo(phx_event_target, phx_event);
        } else if (!this.el.contains(event.target)) {
          this.pushEventTo(`#${this.el.id}`, "hide_dropdown");
        }
      };
      window.addEventListener("click", autocomplete_clickaway_listener);
    },
    destroyed() {
      window.removeEventListener("click", autocomplete_clickaway_listener);
    }
  },
  Select: {
    mounted() {
      const phx_event = this.el.getAttribute("event");
      const phx_event_target = this.el.getAttribute("event-target");

      this.el.addEventListener("change", event => {
        this.pushEventTo(phx_event_target, phx_event, {
          id: event.target.value
        });
      });
    }
  },
  ConversionSelect: {
    mounted() {
      const phx_event = this.el.getAttribute("event");
      const phx_event_target = this.el.getAttribute("event-target");
      const food = this.el.getAttribute("food");
      const recipe = this.el.getAttribute("recipe");

      this.el.addEventListener("change", event => {
        let params = food ? { food } : { recipe };
        params = { ...params, conversion: event.target.value };
        this.pushEventTo(phx_event_target, phx_event, params);
      });
    }
  },
  CopyAttributes: {
    mounted() {
      clipboard = new ClipboardJS(".copy-btn");
    },
    destroyed() {
      clipboard.destroy();
    }
  },
  Tooltips: {
    mounted() {
      // pass "show" to force display of tooltips (i.e. during development)
      $("[data-toggle='tooltip']").tooltip();

      $("[data-toggle='tooltip']").on("click", event => {
        $(event.target).tooltip("hide");
        // in case click registered on a nested element
        // $(event.target.parentElement).tooltip("hide");
      });
    }
  },
  PhotoPopover: {
    mounted() {
      $(this.el).popover({
        trigger: "hover",
        container: this.el,
        html: true,
        content: `<img src=${this.el.dataset.img_url}></img>`
      });
    }
  },
  PhotoUpload: {
    mounted() {
      const { upload_url, phx_event, phx_event_target } = this.el.dataset;
      new Dropzone(this.el, {
        url: upload_url,
        maxFiles: 1,
        acceptedFiles: "image/*",
        previewsContainer: ".dropzone-previews",
        success: (file, { file_path }) => {
          this.pushEventTo(phx_event_target, phx_event, { file_path });
        }
      });
    }
  }
};

const csrfToken = document
  .querySelector("meta[name='csrf-token']")
  .getAttribute("content");

window.liveSocket = new LiveSocket("/live", Socket, {
  hooks,
  params: { _csrf_token: csrfToken }
});

liveSocket.connect();

// $('.global.alert').alert(); // for global alerts in flash_messages.html.slim
